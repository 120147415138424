import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import QRCode from './qrcode';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GenerateQRCode from './qrcode/generateqrcode';
import ScannedDetails from './qrcode/ScannedDetails';
import EditDetails from './qrcode/edit';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<QRCode />} />
        <Route path="/generateqrcode" element={<GenerateQRCode />} />
        <Route path="/view/:token" element={<ScannedDetails />} />
        <Route path="/edit" element={<EditDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
